<template>
  <div>
    <b-modal id="modal-text" size="sm" hide-header hide-footer centered>
      <p class="header py-2">กรุณารอรับอีเมล</p>
    </b-modal>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.header {
  font-size: 18px;
  text-align: center;
  margin: 0;
}
</style>
