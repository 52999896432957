<template>
  <div>
    <HeaderPanel
      title="Sale Order"
      :filter="filter"
      placeholder="Search Ticket No. / Tel No."
      @search="getSaleOrderList"
      @sidebar="sidebarFilter"
      :hasDropdown="false"
      :hideCreate="false"
      btnExport
      @openModalExport="openModalExport"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <Table
        :filter="filter"
        :pageOptions="pageOptions"
        :fields="fields"
        :items="items"
        :isBusy="isBusy"
        :rows="rows"
        @filterPage="filterPage"
        @handleDelete="handleDelete"
      />
    </div>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      placeholder="Search Telephone"
      @searchAll="getSaleOrderList"
      @clearFilter="clearFilter"
      :hideStatusFilter="false"
    >
      <template v-slot:filter-option>
        <div class="mt-3 mb-3">
          <div class="font-weight-bold mb-2">Start Date</div>

          <div :class="['input-container']">
            <datetime
              :input-style="styleDatetime"
              format="dd/MM/yyyy"
              value-zone="local"
              v-model="filter.start_datetime"
              placeholder="Please Select Date"
              ref="startDate"
            >
            </datetime>
            <div
              class="icon-primary text-right"
              @click="$refs.startDate.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                class="pointer color-primary"
                color="#B41BB4"
              />
            </div>
          </div>
        </div>
        <div class="mt-3 mb-3">
          <div class="font-weight-bold mb-2">End Date</div>
          <div :class="['input-container']">
            <datetime
              :input-style="styleDatetime"
              format="dd/MM/yyyy"
              value-zone="local"
              v-model="filter.end_datetime"
              placeholder="Please Select Date"
              ref="endDate"
            >
            </datetime>
            <div
              class="icon-primary text-right"
              @click="$refs.endDate.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                class="pointer color-primary"
                color="#B41BB4"
              />
            </div>
          </div>
        </div>
        <b-form-group>
          <template #label>
            <b>Sales Channel </b><br />
            <b-form-checkbox
              v-model="channelOptions"
              aria-describedby="Status"
              aria-controls="Status"
              @change="channelToggle"
            >
              All
            </b-form-checkbox>
          </template>

          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              class="ml-4"
              id="checkbox-group-2"
              stacked
              v-model="filter.type"
              :aria-describedby="ariaDescribedby"
            >
              <b-form-checkbox
                :value="opt.id"
                :id="opt.name"
                v-for="opt of channelList"
                :key="opt.id"
                >{{ opt.name }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </template>
        </b-form-group>
      </template>
    </SideBarFilter>
    <ModalConfirm
      ref="confirmModal"
      :text="selectedDelete.msg"
      @handler="deleteSaleOrder"
    />
    <ModalExport ref="modalExport" @confirmEmail="confirmEmail" />
    <ModalText />
  </div>
</template>
<script>
import Table from "./Table";
import ModalConfirm from "@/components/modal/ModalConfirm";
import ModalExport from "@/components/modal/ModalExport";
import ModalText from "@/components/modal/ModalText";

import axios from "axios";

export default {
  name: "admin",
  components: {
    Table,
    ModalConfirm,
    ModalExport,
    ModalText,
  },
  data() {
    return {
      selectedDelete: {
        msg: "",
        id: 0,
      },
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        start_datetime: null,
        end_datetime: null,
        type: [],
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      fields: [
        {
          key: "ticket_no",
          label: "Ticket No.",
        },
        {
          key: "telephone",
          label: "Member Telephone",
        },
        {
          key: "grand_total",
          label: "Grand Total",
        },
        {
          key: "type_name",
          label: "Sales Channel",
        },
        {
          key: "branch_name",
          label: "Branch Name",
        },
        {
          key: "created_time",
          label: "Created Date",
        },
        { key: "actions", label: "Action" },
      ],
      items: [],
      statusList: [],
      channelOptions: false,
      channelList: [],
    };
  },
  created() {
    this.getSaleOrderList();
    this.getChannelList();
  },
  watch: {
    "filter.type"(newValue) {
      if (newValue.length === 0) {
        this.channelOptions = false;
      } else if (newValue.length === this.channelList.length) {
        this.channelOptions = true;
      } else {
        this.channelOptions = false;
      }
    },
  },
  methods: {
    channelToggle(checked) {
      this.filter.type = checked ? this.channelList.map((el) => el.id) : [];
    },
    openModalExport() {
      this.$bvModal.show("modal-export");
    },
    async confirmEmail(response) {
      let body = {
        ...response,
        ...this.filter,
      };

      const isExport = await axios.post(
        `${this.$apiPath}/saleorder/admin/export`,
        body
      );

      if (isExport.data.result == 1) {
        this.$refs.modalExport.isLoading = false;
        this.$bvModal.hide("modal-export");

        this.$bvModal.show("modal-text");
      }
    },

    async getChannelList() {
      this.channelList = [
        {
          id: 1,
          name: "POS",
        },
        {
          id: 2,
          name: "LINE",
        },
      ];
    },
    async getSaleOrderList() {
      this.isBusy = true;
      let filter = { ...this.filter };
      filter.type =
        filter.type.length == 0
          ? null
          : filter.type.length == this.channelList.length
          ? null
          : filter.type;
      await this.$store.dispatch("getSaleOrderList", filter);
      let data = this.$store.state.saleOrder.formList;
      if (data.result == 1) {
        this.items = data.detail.datas;
        this.rows = data.detail.total;
        this.isBusy = false;
        this.isLoading = false;
      } else {
        this.errorAlert();
      }
    },

    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    filterPage(filter) {
      this.filter = filter;
      this.getSaleOrderList();
    },
    clearFilter() {
      this.filter = {
        search: "",
        start_datetime: null,
        end_datetime: null,
        type: [],
        page: 1,
        take: 10,
      };
      this.getSaleOrderList();
      this.$refs.sidebarFilter.hide();
    },
    handleDelete(item) {
      this.selectedDelete.msg = `Confirm Delete ${item.ticket_no} ?`;
      this.selectedDelete.id = item.id;
      this.deleteSaleOrder();
    },
    deleteSaleOrder() {
      this.confirmAlert(this.selectedDelete.msg).then(async ({ value }) => {
        if (value) {
          await this.$store.dispatch("setMainLoading", true);
          this.isLoading = true;
          await this.$store.dispatch("deleteSaleOrder", this.selectedDelete.id);
          let data = this.$store.state.saleOrder.respDelete;
          if (data.result == 1) {
            this.successAlert().then(() => this.getSaleOrderList());
          } else {
            this.errorAlert(data.message);
          }
          this.isLoading = false;
          await this.$store.dispatch("setMainLoading", false);
        }
      });
    },
  },
};
</script>
<style lang="scss">
::v-deep .vdatetime-popup {
  z-index: 9999 !important;
}
::v-deep .date-container {
  input {
    color: var(--font-main-color) !important;
    border: 1px solid #d8dbe0 !important;
    border-radius: 0.25rem !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem !important;
  }
}
.input-date {
  position: relative;
  svg {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-75%, 10px);
  }
}
</style>
