<template>
  <div>
    <b-modal v-model="isModal" id="modal-export" hide-header hide-footer>
      <div class="modal-export">
        <p class="header">กรุณากรอกอีเมลเพื่อนำออกข้อมูล</p>
        <InputText
          class="my-4 login-input"
          v-model="email"
          textFloat="Email"
          placeholder="Email"
          type="email"
          name="email"
          isRequired
          :isValidate="$v.email.$error"
          :v="$v.email"
        />
        <b-form-checkbox
          v-model="checkAll"
          :value="true"
          :unchecked-value="false"
        >
          เลือกทั้งหมด
        </b-form-checkbox>
        <div class="footer-export">
          <b-button class="btn btn-filter" @click="closeModal">
            ยกเลิก
          </b-button>
          <div class="mx-1"></div>
          <b-button class="btn btn-filter confirm" @click="confirmEmail">
            ยืนยัน
            <b-spinner
              class="ml-2"
              small
              label="Loading..."
              v-if="isLoading"
            ></b-spinner>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      email: "",
      checkAll: false,

      //   state toggle
      isModal: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      email: { required, email },
    };
  },
  watch: {
    isModal(val) {
      if (!val) {
        this.$v.email.$reset();
        this.email = "";
        this.checkAll = false;
      }
    },
  },
  methods: {
    confirmEmail() {
      this.$v.email.$touch();

      if (this.$v.email.$error) {
        return;
      }

      let response = {
        email: this.email,
        is_take_all: this.checkAll,
      };

      this.isLoading = true;
      this.$emit("confirmEmail", response);
    },
    closeModal() {
      this.$bvModal.hide("modal-export");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-export {
  .header {
    margin: 0;
    font-size: 18px;
  }

  .footer-export {
    display: flex;
    margin-top: 15px;

    .btn {
      width: 100%;

      &.confirm {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
}
</style>
